import { gql } from '@apollo/client';

export default gql`
  query ContentElementsQuery(
    $contentElementIds: [ID!]
    $languages: [String]
  ) {
    content {
      menu_categories_content_elements(
        filter: { id: { _in: $contentElementIds } }
      ) {
        id
        sort
        item {
          __typename
          ...ContentElement1Col
          ...ContentElement2Col
          ...ContentElement2ColGrid
        }
      }
    }
  }

  fragment ContentElement1Col on content_elements_1col {
    id
    title
    background
    image {
      full_url
    }
    image_style
    text_style
    translations(filter: { languages_code: { code: { _in: $languages } } }) {
      languages_code {
        code
      }
      content
    }
  }

  fragment ContentElement2Col on content_elements_2_col {
    id
    title
    background
    image {
      full_url
    }
    image_style
    text_position
    translations(filter: { languages_code: { code: { _in: $languages } } }) {
      languages_code {
        code
      }
      content
    }
  }

  fragment ContentElement2ColGrid on content_elements_2col_grid {
    id
    title
    background
    image {
      full_url
    }
    image_left {
      full_url
    }
    image_right {
      full_url
    }
    translations(filter: { languages_code: { code: { _in: $languages } } }) {
      languages_code {
        code
      }
      main_content
      content_left
      content_right
    }
  }
`;
